import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import BrochureList from "../components/brochureComponents/BrochureList";
import OurTip from "../components/brochureComponents/OurTip";
import TextureComponent from "../components/slices/textureComponent/textureComponent";
import { HeroComponent } from "../components/slices";
import "../styles/Brochures.scss";
import browserStorage from "@utils/browserStorage";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Brochure = ({ data }) => {
  if (!data) return null;
  browserStorage.setLanguage(data.prismicHeaderitem.lang);
  const header = data.prismicHeaderitem ?? {};
  const footer = data.prismicFooteritem ?? {};
  const { lang, type, url } = header ?? {};
  const alternateLanguages = header.alternate_languages ?? [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const translationsDataForForm = data.prismicBrochures.data.body[0];
  const dataHeroComponent = data.prismicBrochures.data.body[1];
  const dataBrochureListItems = data.prismicBrochures.data.body[2]?.items;
  const dataOurTip = data.prismicBrochures.data.body[3];
  const dataBrochureListSecondItems = data.prismicBrochures.data.body[4]?.items;
  const dataForTextureComponent = data.prismicHomepage.data.body[9];

  return (
    <Layout
      activeDocMeta={activeDoc}
      body={header}
      bodyFooter={footer}
      fullPage={false}
      translationsDataForForm={translationsDataForForm}
    >
      <div className="brochuresPage">
        <ToastContainer />

        <HeroComponent slice={dataHeroComponent} />

        <BrochureList items={dataBrochureListItems} />

        <OurTip slice={dataOurTip} />

        <BrochureList items={dataBrochureListSecondItems} />

        <TextureComponent slice={dataForTextureComponent} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query brochuresQuery($lang: String) {
    prismicHeaderitem(lang: { eq: $lang }) {
      lang
      type
      url
      alternate_languages {
        lang
        type
      }
      data {
        button {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        aboutus {
          aboutus_name {
            text
          }
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        item7 {
          text
        }
        solutions {
          solution_name {
            text
          }
        }
        products {
          product_name {
            text
          }
        }
        cookie_consent {
          raw
        }
      }
    }
    prismicHomepage(lang: { eq: $lang }) {
      lang
      url
      data {
        body {
          ... on PrismicHomepageBodyTextureSection {
            id
            slice_type
            primary {
              buttontext {
                text
              }
              emailtext {
                text
              }
              title {
                text
              }
            }
          }
        }
      }
    }
    prismicBrochures(lang: { eq: $lang }) {
      lang
      data {
        body {
          ... on PrismicBrochuresBodyHeroSection {
            id
            slice_type
            primary {
              title {
                text
                raw
              }
              subtitle {
                text
                raw
              }
              background_image {
                url
              }
            }
          }
          ... on PrismicBrochuresBodyBrochurestextslider {
            id
            items {
              title {
                text
              }
              list {
                raw
              }
              btn_label {
                text
              }
              description {
                text
              }
              description_second {
                text
              }
              brochurename {
                text
              }
              image {
                url
              }
            }
          }
          ... on PrismicBrochuresBodyBrochuresitems {
            id
            items {
              description {
                text
              }
              image {
                url
              }
              title {
                text
              }
              brochurename {
                text
              }
              btn_label {
                text
              }
            }
          }
          ... on PrismicBrochuresBodyProductFormTranslate {
            id
            primary {
              title {
                text
              }
              back {
                text
              }
              btn_label {
                text
              }
              confirm {
                text
              }
              company {
                text
              }
              email {
                text
              }
              country {
                text
              }
              female {
                text
              }
              male {
                text
              }
              jobtitle {
                text
              }
              name {
                text
              }
              phone {
                text
              }
              phone_code {
                text
              }
              subscribe {
                text
              }
              submit_error {
                text
              }
              submit_success {
                text
              }
            }
          }
        }
      }
    }
    prismicFooteritem(lang: { eq: $lang }) {
      url
      type
      lang
      alternate_languages {
        lang
        type
      }
      data {
        about {
          text
        }
        allrights {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        item7 {
          text
        }
        privacy {
          text
        }
        terms {
          text
        }
        clientinformation {
          text
        }
        impressum {
          text
        }
      }
    }
  }
`;

export default Brochure;
